<template>
  <div>
    <v-row align="center" no-gutters>
      <v-col cols="6" sm="4">
        <v-btn color="info" @click="addBusinessHour" outlined
          >+ Add Business Hours</v-btn
        >
      </v-col>
      <v-col cols="4" sm="4" md="3">
        <v-switch
          color="error"
          v-model="is_business_close"
          label="We are Closed"
          @change="showCloseDialog"
        ></v-switch>
      </v-col>
      <v-col cols="2" sm="4" md="3">
        <img
          width="90"
          v-if="is_business_close"
          src="/svg/noun_closed_2640959.svg"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="space-between"
      v-for="(business_hour, key) in business_hours"
      :key="key"
      no-gutters
    >
      <v-col cols="12" md="4">
        <ValidationProvider
          v-slot="{ errors }"
          name="Days"
          rules="required"
          immediate
        >
          <v-select
            prepend-icon="calendar_today"
            multiple
            :items="weekdays"
            class="body-2"
            v-model="business_hours[key].business_days"
            label="Open days"
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="6" sm="4" md="2" lg="2" class="mx-1">
        <ValidationProvider
          v-slot="{ errors }"
          name="from"
          rules="required"
          immediate
        >
          <v-select
            :items="startHourList"
            v-model="business_hours[key].fromHour"
            label="from"
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="5" sm="4" md="2" lg="2">
        <ValidationProvider
          v-slot="{ errors }"
          name="to"
          rules="required"
          immediate
        >
          <v-select
            :items="endHourList"
            v-model="business_hours[key].endHour"
            label="to"
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col
        cols="5"
        sm="3"
        v-if="business_hours[key].fromHour && business_hours[key].endHour"
      >
        <div class="d-flex align-center justify-around">
          <p class="mx-2 mt-2">
            <small
              >({{
                diffHours(
                  business_hours[key].fromHour,
                  business_hours[key].endHour
                )
              }})</small
            >
          </p>
          <v-btn text color="red" @click="removeHour(key)">
            <v-icon dark>delete</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="closeDialog" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon class="mr-3" color="grey">fastfood</v-icon>
          {{ closeTitle }}
        </v-card-title>
        <v-card-actions>
          <!-- <v-btn text @click="closeDialog = false, is_business_close != is_business_close">{{ $t('Common.cancel') }}</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn text @click.stop="updateIsBusinessClose">{{
            closeAntiAction
          }}</v-btn>
          <v-btn color="takein" dark @click.stop="turnOffPreOrder">{{
            closeAction
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    isBusinessClose: {
      Type: Boolean
    },
    businessHours: {
      Type: Array
    },
    allow_preorder: {
      Type: Boolean
    }
  },
  data() {
    return {
      weekdays: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      is_business_close: false,
      business_hours: [],
      startHourList: null,
      endHourList: null,
      closeDialog: false,
      closeTitle: '',
      closeAction: '',
      closeAntiAction: '',
      allowPreorder: false
    }
  },
  mounted() {
    this.is_business_close = !!this.isBusinessClose
    this.business_hours = this.businessHours || []
    this.allowPreorder = this.allow_preorder || false
    this.startHourList = this.eachHalfHour('00:00', `23:30`)
    this.endHourList = this.eachHalfHour(`00:00`, '23:30')
  },
  watch: {
    business_hours: {
      handler: function() {
        // TODO: lets remove adding weekday after release 1.3.3 of mobile app.
        for (let index = 0; index < this.business_hours.length; index++) {
          let element = this.business_hours[index]
          element.weekday = this.business_hours[index].business_days
          this.business_hours[index] = element
        }
        console.log('Business Hour Changed', this.business_hours)
        this.$emit('onBusinessHoursUpdate', this.business_hours)
      },
      deep: true
    }
  },
  methods: {
    diffHours(startTime, endTime) {
      const mmtStartTime = moment(startTime, 'HH:mm a')
      const mmtEndTime = moment(endTime, 'HH:mm a')
      // calculate total duration
      let duration, hours, minutes
      if (mmtStartTime.isBefore(mmtEndTime)) {
        duration = moment.duration(mmtEndTime.diff(mmtStartTime))
        hours = parseInt(duration.asHours())
      } else {
        // duration = moment.duration(mmtStartTime.diff(mmtEndTime))
        // hours = 24 - Math.ceil(duration.asHours())
        return `${startTime} is after ${endTime}`
      }
      minutes = parseInt(duration.asMinutes()) % 60

      return hours + 'Hours' + (minutes > 0 ? ' ' + minutes + 'Mins' : '')
    },
    addBusinessHour() {
      this.business_hours.push({
        business_days: null,
        fromHour: '08:00 am',
        endHour: '08:00 pm'
      })
    },
    removeHour(key) {
      this.business_hours.splice(key, 1)
    },
    showCloseDialog() {
      if (this.is_business_close == this.allowPreorder) {
        if (this.is_business_close) {
          this.closeTitle = this.$t('Common.disable_preorder_business_hours')
          this.closeAction = this.$t('Common.disable')
          this.closeAntiAction = this.$t('Common.leave_preorder_on')
        } else {
          this.closeTitle = this.$t('Common.enable_preorder_business_hours')
          this.closeAction = this.$t('Common.enable')
          this.closeAntiAction = this.$t('Common.leave_preorder_off')
        }
        this.closeDialog = true
      } else {
        this.updateIsBusinessClose()
      }
    },
    turnOffPreOrder() {
      this.updateIsBusinessClose()
      this.$store.dispatch('saveSettings', {
        allow_preorder: !this.allowPreorder
      })
    },
    updateIsBusinessClose() {
      this.$emit('onBusinessClose', { isBusinessClose: this.is_business_close })
      this.closeDialog = false
    }
  }
}
</script>
